<template>
  <v-container fluid grey darken-4 white--text>
    <v-row>
      <h1>
        Lifestyle Blog 
      </h1>
    </v-row>
    <ul>
      <div id="div" v-for="post in posts" :key="post.id">
        <router-link style="text-decoration: none;" :to="'/blog/' + post.id">
          <BlogPostPreview :post="post"></BlogPostPreview>
        </router-link>
      </div>
    </ul>
  </v-container>
</template>

<script>
import BlogPostPreview from '../components/BlogPostPreview.vue'
import axios from 'axios'
import {api} from '../settings.js'

export default {
    components: {BlogPostPreview},
    
    data(){
      return {
        posts: []
      };
      
    },
    methods: {
    },
    computed: {
      },
     
     mounted() {
    axios.get(api + "/lifestyleBlog/blogposts").then(response => {
      this.posts = response.data;
      this.posts.forEach(element => {
        element.image = api + element.image;
      });
      this.posts = this.posts.reverse();
    })
    }

}
</script>

<style scoped>
</style>