<template>

<div
id="div">
    <v-img
    class="my-10 ml-2" 
    id="image"
    max-height="400"
    max-width="100%"
    :src="post.image"
    >
    <h1>
        {{post.title}}
    </h1>
    </v-img>
    </div>

</template>

<script>
export default {
    props: {
        post: Object
    }
}
</script>

<style scoped>
#image{
    filter: grayscale(95%);
}

#image:hover{
    filter: grayscale(0%);
}

#div{
    background-color: #fff200;
}
#div:hover{
    transform: scale(1.0125);
}

h1{
    color: #ffffff;
    background-color: rgba(33,33,33,0.4);
    filter: grayscale(0%);
    text-align: left;
    padding: 10px;
    width: max-content;
    border-radius: 0px 50px 50px 0px;
}
</style>